<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
  <!-- EOC -->
  <!-- BOC:[error] -->
  <AError :api="api"></AError>
  <!-- EOC -->
  <!-- BOC:[loader] -->
  <ALoader :isLoading="api.isLoading"></ALoader>
  <!-- EOC -->
  <!-- BOC:[content] -->
  <div v-if="me.id">
    <!-- BOC:[parents] -->
    <v-chip-group
      v-if="model.parents.length > 0"
      column
    >
      <template v-for="parent in model.parents">
        <v-chip
          v-if="me[parent]"
          :key="parent"
          :to="($_hasRoute({name:'Page'+role+parent+'Read',params:{id:me[parent].id}})) ? {name:'Page'+role+parent+'Read',params:{id:me[parent].id},query:{tab:model.key}} : {name:'Page'+role+'DataRead',params:{id:me[parent].id,object:$_lcfirst(parent)},query:{tab:model.key}}"
          label
          outlined
          link
        >
          {{ (me[parent].translatableName) ? JSON.parse(me[parent].translatableName)[$_getLocale()] : (me[parent].name) ? me[parent].name : `${parent} ${me[parent].id}` }}
        </v-chip>
      </template>
    </v-chip-group>
    <!-- EOC -->
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col class="col-12 col-sm-6"><h1>{{(me.name) ? me.name : `${model.name.singular} ${me.id}`}}</h1></v-col>
      <v-col class="col-12 col-sm-6 text-right">
        <BreadReadButtonDelete
          v-if="model[role].delete"
          class="ml-2"
          :me="me"
          :role="role"
          :model="model"
        ></BreadReadButtonDelete>
        <BreadReadButtonEdit
          v-if="model[role].edit"
          class="ml-2"
          :me="me"
          :role="role"
          :model="model"
        ></BreadReadButtonEdit>
      </v-col>
    </v-row>
    <!-- EOC -->
    <!-- BOC:[tabs] -->
    <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab
        v-for="item in model[role].read.tabs"
        @click="updateTab(item.key)"
        :key="item.key"
        :href="`#${item.key}`"
      >
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <!-- EOC -->
    <!-- BOC:[tab] -->
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in model[role].read.tabs"
        class="px-1 py-2"
        :value="item.key"
        :key="item.key"
      >
        <BreadReadInfo
          v-if="item.key == 'Info'"
          :role="role"
          :model="model"
          :url="url"
          :data="me"
        ></BreadReadInfo>
        <component v-else :is="'Tab'+role+model.key+item.key"></component>
      </v-tab-item>
    </v-tabs-items>
    <!-- EOC -->
  </div>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import models from '@/models/index'
  //EOC
  import BreadReadButtonDelete from '@/components/Bread/BreadReadButtonDelete'
  import BreadReadButtonEdit from '@/components/Bread/BreadReadButtonEdit'
  import BreadReadInfo from '@/components/Bread/BreadReadInfo'
  import { mapState } from 'vuex'
  export default {
    metaInfo() {
      return {
        title:this.model.name.singular + ' Info | Hamochi',
      }
    },
    components:{
      BreadReadButtonDelete,
      BreadReadButtonEdit,
      BreadReadInfo,
    },
    computed: mapState({
      //
    }),
    props:[
      'self',
      'role',
      'url',
    ],
    data: () => ({
      //BOC:[model]
      model: {},
      //EOC
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[tabs]
      tab:null,
      //EOC
      //BOC:[api]
      api:{
        isLoading:false,
        isError:false,
        error:null,
        url:null,
      },
      //EOC
      //BOC:[self]
      me:{
        id:null,
      },
      //EOC
    }),
    created() {
      //BOC:[model]
      this.model = models[this.$route.params.object]
      //EOC
      //BOC:[guard]
      if(!this.model[this.role].read) {
        this.$router.replace(this.$_getRouteBrowse(this.role,this.model.key))
        return
      }
      //EOC
      //BOC:[self]
      if(this.self) this.me = this.$_.cloneDeep(this.self)
      //EOC
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:'Dashboard',
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.model.name.plural,
        to:this.$_getRouteBrowse(this.role,this.model.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.me.name) ? this.me.name : `${this.model.name.singular} ${this.$route.params.id}`,
        to:this.$_getRouteRead(this.role,this.model.key,this.$route.params.id),
        exact:true,
      })
      //EOC
      //BOC:[tabs]
      if(this.$route.query.tab) this.tab = this.$route.query.tab
      //EOC
      //BOC:[url]
      this.api.url = (this.url) ? this.url : `${this.$api.servers[this.role.toLowerCase()]}/v1/bread/${this.$route.params.object}/${this.$route.params.id}`
      //EOC
      //BOC:[api]
      this.api.method = 'get'
      this.api.callbackReset = () => {
        this.api.isLoading = true
        this.api.isError = false
      }
      this.api.callbackError = (e) => {
        this.api.isLoading = false
        this.api.isError = true
        this.api.error = e
      }
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false
        this.me = resp
        //BOC:[breadcrumbs]
        if(resp.name) this.breadcrumbs[this.breadcrumbs.length-1].text = resp.name
        //EOC
      }
      //EOC
    },
    mounted() {
      //BOC:[self]
      if(!this.me.id) this.$api.fetch(this.api)
      //EOC
    },
    methods: {
      //BOC:[tabs]
      updateTab(tab) {
        window.history.pushState('','','?tab='+ tab);
      },
      //EOC
    }
  }
</script>